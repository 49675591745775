import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.find.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import { PROPERTY_TYPE_MAIN_PROPERTY, PROPERTY_TYPE_CONDO, PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT, PROPERTY_TYPE_UNKNOWN, getPropertyType } from "~/helpers/property-helpers.js";
export var getLatestCompanyKeyFigure = function getLatestCompanyKeyFigure(company) {
  return getLatestCompanyKeyFigureFiltered(company, function () {
    return true;
  });
};
export var getLatestCompanyKeyFigureWithFinancialData = function getLatestCompanyKeyFigureWithFinancialData(company) {
  return getLatestCompanyKeyFigureFiltered(company, function (a) {
    return a != null && (a.balance || a.ebit || a.netIncome);
  });
};
var getLatestCompanyKeyFigureFiltered = function getLatestCompanyKeyFigureFiltered(company, filter) {
  if (!(company !== null && company !== void 0 && company.keyFigures)) {
    return null;
  }
  var sorted = _toConsumableArray(company === null || company === void 0 ? void 0 : company.keyFigures).filter(filter).sort(function (a, b) {
    return a.year - b.year;
  });
  if (sorted.length === 0) {
    return {};
  }
  var out = {};
  var latest = sorted[sorted.length - 1];
  Object.keys(latest).forEach(function (key) {
    out[key] = {
      value: latest[key]
    };
  });
  if (sorted.length > 1) {
    var previous = sorted[sorted.length - 2];
    Object.keys(previous).forEach(function (key) {
      var percentageDiff = (latest[key] - previous[key]) / previous[key] * 100;
      out[key].development = percentageDiff;
    });
  }
  return out;
};
var getCompanyProperies = function getCompanyProperies(companies) {
  return companies === null || companies === void 0 ? void 0 : companies.flatMap(function (company) {
    return company.properties;
  }).filter(function (property) {
    return property;
  });
};
export var getCompanyMainProperties = function getCompanyMainProperties(companies) {
  var _getCompanyProperies;
  return ((_getCompanyProperies = getCompanyProperies(companies)) === null || _getCompanyProperies === void 0 ? void 0 : _getCompanyProperies.filter(function (prop) {
    return getPropertyType(prop) == PROPERTY_TYPE_MAIN_PROPERTY;
  })) || [];
};
export var getCompanyCondos = function getCompanyCondos(companies) {
  var _getCompanyProperies2;
  return ((_getCompanyProperies2 = getCompanyProperies(companies)) === null || _getCompanyProperies2 === void 0 ? void 0 : _getCompanyProperies2.filter(function (prop) {
    return getPropertyType(prop) == PROPERTY_TYPE_CONDO;
  })) || [];
};
export var getCompanyBofp = function getCompanyBofp(companies) {
  var _getCompanyProperies3;
  return ((_getCompanyProperies3 = getCompanyProperies(companies)) === null || _getCompanyProperies3 === void 0 ? void 0 : _getCompanyProperies3.filter(function (prop) {
    return getPropertyType(prop) == PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT;
  })) || [];
};
export var getCompanyUnknownProperties = function getCompanyUnknownProperties(companies) {
  var _getCompanyProperies4;
  return ((_getCompanyProperies4 = getCompanyProperies(companies)) === null || _getCompanyProperies4 === void 0 ? void 0 : _getCompanyProperies4.filter(function (prop) {
    return getPropertyType(prop) == PROPERTY_TYPE_UNKNOWN;
  })) || [];
};
export var realOwners = function realOwners(owners) {
  // filtering out members such as accountants and corporate owners

  var people = owners === null || owners === void 0 ? void 0 : owners.filter(function (mbr) {
    return mbr.entityType != "VIRKSOMHED";
  });
  var results = [];
  if (people === undefined || people.length == 0) {
    return results;
  }
  for (var i = 0; i < people.length; i++) {
    var person = people[i];
    // remapping all nested attributes to single array

    var attributes = person.organizations.flatMap(function (org) {
      return org.attributes;
    });
    var share = undefined;
    if (attributes.some(function (attr) {
      return (attr === null || attr === void 0 ? void 0 : attr.value.toLowerCase()) === "reel ejer" && !attr.validTo;
    })) {
      share = attributes.find(function (attr) {
        return attr && attr.type.toLowerCase() == "ejerandel_procent" && !attr.validTo;
      });
    }
    if (share) {
      results.push({
        person: person,
        share: share.value
      });
    }
  }
  return results;
};